/**
 * Widgetized search form interactions
 *
 * @since   1.0.0
 * @package aaa
 */

(function($) {

	var $document = $( document );
	$document.ready( init );

	/**
	 * Start setting/binding document-ready stuff
	 *
	 * @since 1.0.0
	 */
	function init() {

		var formSelector  = '.widget-search [role="search"]',
		    $form         = $( formSelector ),
		    $fieldsToggle = $( '.search-widget-toggle' );

		if ( $form.length < 1 ) {
			return;
		}

		$fieldsToggle.on( 'click', doFieldsToggle );
	}


	/**
	 * Show/hide full search form fields
	 *
	 * @since 1.0.0
	 */
	function doFieldsToggle() {

		$this = $(this);

		// toggle stuff
		var $form = $this.parent(),
			$form = $( '[role="search"]', $form );

		$form.slideToggle();
	}

})(jQuery);
