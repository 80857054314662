/**
 * Add Font Awesome icons to stuff
 *
 * @since   1.0.0
 * @package bigblue
 */

(function($) {
	$(document).ready(function() {

		$.each({
			'ul.highlighted > li'                    : 'chevron-right',
			'ul.highlighted ul li'                   : 'genderless',
			'.validation_message'                    : 'exclamation-triangle',
			'.ginput_container_date'                 : 'calendar-alt',
		}, prependIcon );

		/**
		 * Prepend an icon to a jQuery object
		 *
		 * @param {string} thing selector
		 * @param {string} icon  name of Font Awesome icon (like file-text)
		 */
		function prependIcon( thing, icon ) {
			$( thing ).prepend( '<i class="fas fa-' + icon + '"></i>' );
		}

	}); // document.ready
})(jQuery);
