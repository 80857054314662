/**
 * WP Triggers plugin helper
 *
 * @since   1.0.0
 * @package aaa
 */
(function($) {

	var $document = $( document ),
		$wrapper  = $( '.wptrigger_contents' ),
		$zipcode  = $( '.wptgg_pass_key1' ),
	    $submit   = $( '[type="submit"]', $wrapper );

	$document.ready( init );


	/**
	 * Start setting/binding document-ready stuff
	 *
	 * @since 1.0.0
	 */
	function init() {
		$submit.on( 'click', doValidationCheck );

		$zipcode.on( 'change', removeError );
		$zipcode.on( 'keyup', function( e ) {

			if ( e.keyCode == 13 ) {
				doValidationCheck();
			}
		});
	}


	/**
	 * Check zip field
	 *
	 * @since 1.0.0
	 */
	function doValidationCheck() {

		if ( $zipcode.val() ) {
			removeError();
		} else {
			$zipcode.addClass( 'error' );
		}
	}


	/**
	 * Remove zip error
	 *
	 * @since 1.0.0
	 */
	function removeError() {
		$zipcode.removeClass( 'error' );
	}

})(jQuery);
