/**
 * Full earch form interactions
 *
 * @since   1.0.0
 * @package aaa
 */

(function($) {

	var $document = $( document );
	$document.ready( init );

	/**
	 * Start setting/binding document-ready stuff
	 *
	 * @since 1.0.0
	 */
	function init() {

		formSelector  = '.full-search [role="search"]';
		$form         = $( formSelector );
		$fieldsToggle = $( '#search-fields-toggle' );

		if ( $form.length < 1 ) {
			return;
		}

		$fieldsToggle.on( 'click', doFieldsToggle );
	}


	/**
	 * Show/hide full search form fields
	 *
	 * @since 1.0.0
	 */
	function doFieldsToggle() {

		$this = $(this);

		// toggle stuff
		$form.slideToggle();
		$this.toggleClass( 'fields-open' );

		// update button text
		$( '.text', $this ).text( $this.data( $this.hasClass( 'fields-open' )
			? 'opened-text' : 'closed-text' ) );

	}

})(jQuery);
