/**
 * Mobile navigation actions
 *
 * @since   1.0.0
 * @package aaa
 */

(function($) {
	$(document).ready(function() {

		// things
		var $window           = $( window );
		var $topStrip         = $( '#top-strip'                    );
		var $header           = $( 'header[role=banner]'           );
		var $nav              = $( '> div > nav'     , $header     );
		var $navToggle        = $( '#menu-toggle'    , $header     );
		var $searchForm       = $( '[role="search"]' , $header     );
		var $searchFormToggle = $( '#search-toggle'  , $header     );
		var $searchFormClose  = $( '.close'          , $searchForm );
		var $adminBar         = $( '#wpadminbar'                   );
		var $navOverlay       = $( '.nav-overlay'    , $header     );
		var $parentMenuItems  = $( '.menu-item-has-children'       );
		var $subMenuToggles;

		// heights
		var windowHeight;
		var topStripHeight;
		var navHeight;
		var searchFormHeight;
		var adminBarHeight;

		// init stuff
		maybeScrollY();
		addSubmenuToggles();

		// bind events
		$window.resize( maybeScrollY );
		$navToggle.click( toggleMenu );
		$searchFormToggle.click( toggleSearchForm );
		$searchFormClose.click( toggleSearchForm );
		$subMenuToggles.click( toggleSubmenu );
		$navOverlay.click( toggleMenu );


		/**
		 * Toggler: toggle class and position menu
		 *
		 * @since 1.0.0
		 */
		function toggleMenu() {
			$nav.toggleClass( 'toggled' );
			$navToggle.toggleClass( 'toggled' );
			$header.toggleClass( 'nav-toggled' );
			$navOverlay.fadeToggle();
		}


		/**
		 * Toggler: toggle class and position search form
		 *
		 * @since 1.0.0
		 */
		function toggleSearchForm() {
			$searchForm.toggleClass( 'toggled' );
			$searchFormToggle.toggleClass( 'toggled' );
			$header.toggleClass( 'search-toggled' );
			$navOverlay.fadeToggle();
		}


		/**
		 * Get the header, nav, and admin bar heights
		 *
		 * @since 1.0.0
		 */
		function getHeights() {
			windowHeight     = $window.height();
			topStripHeight   = $topStrip.outerHeight();
			headerHeight     = $header.outerHeight();
			adminBarHeight   = $adminBar.outerHeight();
			navHeight        = getChildrenHeight( $nav );
			searchFormHeight = getChildrenHeight( $searchForm );
		}


		/**
		 * Get the height of direct child elements
		 *
		 * @since 1.0.0
		 */
		function getChildrenHeight( $parent ) {

			var height = 0;

			$parent.children().each( function() {

				var $this = $(this);
				var dontCountMargin = $this.attr( 'type' ) == 'submit' && $this.attr( 'role' ) == 'button';

				height += $this.outerHeight( ! dontCountMargin );
			});

			return height;
		}


		/**
		 * Maybe scroll overflow-y
		 *
		 * If the height of the menu goes beyond what's visible on the screen,
		 * allow some scrolling
		 *
		 * @since 1.0.0
		 */
		function maybeScrollY() {

			// reset scroll-y so height is calculated correctly
			$nav.removeClass( 'scroll-y' );
			$searchForm.removeClass( 'scroll-y' );

			// get our heights
			getHeights();

			// calculate usable height
			var usableHeight = windowHeight - topStripHeight - adminBarHeight;

			// compare
			if ( usableHeight < navHeight ) {
				$nav.addClass( 'scroll-y' );
			}
			if ( usableHeight < searchFormHeight ) {
				$searchForm.addClass( 'scroll-y' );
			}
		}


		/**
		 * Add mobile submenu dropdown toggles, then bind them to toggles var
		 *
		 * @since 1.0.0
		 */
		function addSubmenuToggles() {
			$parentMenuItems.each( addSubmenuToggle );
			$subMenuToggles = $( '.toggle-sub', $parentMenuItems );
		}


		/**
		 * Add a submenu toggle
		 *
		 * @since 1.0.0
		 */
		function addSubmenuToggle() {

			var icon = this.parentElement.id == 'primary-menu' ? 'chevron-down' : 'caret-down';

			$(this).append(
				  '<button class="toggle-sub">'
				+      '<span class="screen-reader-text">Menu</span>'
				+      '<i class="fas fa-' + icon + '"></i>'
				+ '</button>'
			);
		}


		/**
		 * Toggle mobile submenu
		 *
		 * @since 1.0.0
		 */
		function toggleSubmenu() {

			var $li = $( this ).parent();
			$li.toggleClass( 'sub-toggled' );

			// double-check the scroll-y
			$( '> .sub-menu', $li ).slideToggle( 300, maybeScrollY );
		}

	}); // document.ready
})(jQuery);
