/**
 * Click events on Users section
 *
 * @since   1.0.0
 * @package aaa
 */
(function($) {

	var $document = $( document );
	$document.ready( init );
	var targetElement = document.querySelector(".featherlight");

	/**
	 * Start setting/binding document-ready stuff
	 *
	 * @since 1.0.0
	 */
	function init() {

		$('.user_bio-link').click(onClickBioLink);

	}


	/**
	 * See if checkboxes and radios are checked, flagging a parent <label>
	 *
	 * @since 1.0.0
	 */
	function onClickBioLink(e){
		// Add the clicked bio to the featherlight content
		$content = $(this).closest('.user_content').clone();
		$content.find(".user_bio-link").remove();

		// Keep the page from scrolling up to top
		e.preventDefault();

		// Display the content
		$.featherlight($content, {
			afterOpen : function(event) {
				bodyScrollLock.disableBodyScroll(targetElement);
			},
			afterClose: function(event) {
				bodyScrollLock.enableBodyScroll(targetElement);
			},
		});

	}

})(jQuery);
