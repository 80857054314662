/**
 * Slider
 *
 * @since   1.0.0
 * @package aaa
 */

(function($) {
    $(document).ready(function() {

        var $slider = $( '.slider' );

        // sanity check
        if ( ! ( 'flickity' in $.fn ) || $slider.length < 1 ) {
            return;
        }

        $slider.flickity({
            wrapAround : true,
        });

    }); // document.ready
})(jQuery);
