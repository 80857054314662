/**
 * Asset single interactions
 *
 * @since   1.0.0
 * @package aaa
 */
(function($) {
	$(document).ready(function() {

		var $ftWrap = $( '.single-aaa_asset #featured-image-wrap' ),
		    $embed  = $( 'iframe', $ftWrap );

		if ( $ftWrap.length < 1 || $embed.length < 1 ) {
			return;
		}

		var $button = $( '.play', $ftWrap ),
			$image  = $( 'img', $ftWrap );

		$button.on( 'click', showEmbed );


		/**
		 * Hide stuff covering video embed and start the video
		 *
		 * @since 1.0.0
		 */
		function showEmbed() {
			$button.fadeOut();
			$image.fadeOut();
			playEmbed();
		}


		/**
		 * Play the embedded video
		 *
		 * @since 1.0.0
		 */
		function playEmbed() {
			var src = $embed.attr( 'src' );
			$embed.attr( 'src', src + '&autoplay=true' );
		}

	}); // document.ready
})(jQuery);
