/**
 * Crazy footer menu column calculations
 *
 * Since browsers apparently don't know how flex "column wrap" should work.
 *
 * @since   1.0.0
 * @package aaa
 */
(function($) {
	$(document).ready(function() {

		$( 'footer[role="contentinfo"] .menu a' ).on( 'click', maybeNoLink );
		$( 'footer[role="contentinfo"] .sub-menu' ).each( maybeSetWidth );


		/**
		 * Don't do link events if hash link
		 *
		 * @param {event}  e
		 * @since 1.0.0
		 */
		function maybeNoLink( e ) {

			if ( this.getAttribute( 'href' ) == '#' ) {
				e.preventDefault();
			}
		}


		/**
		 * Maybe set a min-width on sub-menu, depending on how many children
		 * there are
		 *
		 * @since 1.0.0
		 */
		function maybeSetWidth() {

			var columns = Math.ceil( this.childElementCount / 5 );

			if ( columns < 2 ) {
				return;
			}

			this.style.minWidth = ( columns * this.children[0].offsetWidth ) + 'px';
		}

	}); // document.ready
})(jQuery);
